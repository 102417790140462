import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Header = ({ siteTitle }) => (
  <div
    style={{
      position: 'fixed',
      backgroundColor: '#1D1C1A',
      padding: '1rem',
      textAlign: 'left',
      width: '100%',
      zIndex: '2',
    }}
  >
    <Link
      to="/"
      style={{
        color: 'white',
        textDecoration: 'none',
      }}
    >
      <StaticImage
        src="../images/aiv_logo.svg"
        alt="All In Vision AB"
        width={100}
      />
    </Link>
    <nav>
      <Link to="/">Stories</Link>
      <Link to="/contact">Contact</Link>
    </nav>
  </div>
)

export default Header
